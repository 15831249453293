import { Transform } from 'xyzt';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModule } from '../50-systems/ModuleStore/makers/makeIconModule';
import { makeMultiModule } from '../50-systems/ModuleStore/makers/makeMultiModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { consolex } from '../consolex';

const SCALE_FACTOR = 1.2;

internalModules.declareModule(() =>
    makeMultiModule({
        manifest: {
            name: '@collboard/internal/zoom-tool',
            deprecatedNames: ['@collboard/zoom-tool', 'ZoomTool'],
            title: { en: 'Zoom board', cs: 'Přiblížení' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.33/icons/zoom-in.svg',

            author: Authors.collboard,
            requirePermissions: ['view'],
        },
        modules: [
            makeIconModule({
                toolbar: ToolbarName.Navigation,
                async icon(systems) {
                    const { appState } = await systems.request('appState');
                    return {
                        name: 'zoom-in',
                        icon: 'zoom-in',
                        onClick: async () => {
                            // TODO: DRY
                            consolex.info(`Zooming in`);

                            if (!appState.transform) {
                                // TODO: DRY
                                consolex.warn(`Trying to use appState.transformation but it is still not defined.`);
                                return;
                            }

                            appState.transform.next(appState.transform.value.apply(Transform.scale(SCALE_FACTOR)));
                        },
                    };
                },
            }),
            makeIconModule({
                toolbar: ToolbarName.Navigation,
                async icon(systems) {
                    const { appState } = await systems.request('appState');
                    return {
                        name: 'zoom-out',
                        order: 5,

                        icon: 'zoom-out',

                        onClick: async () => {
                            // TODO: DRY
                            consolex.info(`Zooming out`);

                            if (!appState.transform) {
                                consolex.warn(`Trying to use appState.transformation but it is still not defined.`);
                                return;
                            }

                            appState.transform.next(appState.transform.value.apply(Transform.scale(1 / SCALE_FACTOR)));
                        },
                    };
                },
            }),

            // TODO: Zoom text 100%
            /*
                <Icon icon="zoom-in" inactive />
                <div className="zoom-status inactive">100%</div>
                <Icon icon="zoom-out" inactive />
            */

            // TODO: @roseckyj create icons for rotation
            // TODO: Move somewhere else
            /*
            makeIconModule({
                name: 'rotate',
                order: 7,
                section: 1,
                icon: 'circle',
                iconNotSelectable,
                onClick: async () => {
                    // TODO: DRY
                    consolex.info(`Rotating`);

                    if (!appState.transform) {
                        consolex.warn(`Trying to use appState.transformation but it is still not defined.`);
                        return;
                    }

                    appState.transform.next(appState.transform.value.apply(Transform.rotate(Math.PI / 25)));
                    consolex.log(appState.transform);
                },
            })
            */
        ],
    }),
);
