import { Authors } from '../../ModuleStore/Authors';
import { internalModules } from '../../ModuleStore/internalModules';
import { makeIconModuleOnRoute } from '../../ModuleStore/makers/makeIconModuleOnRoute';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeIconModuleOnRoute({
        manifest: {
            name: '@collboard/internal/add-icon',
            deprecatedNames: ['@collboard/add-icon', 'Upload'],
            title: { en: 'Upload content', cs: 'Nahrát obsah' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.33/icons/image.svg',

            author: Authors.collboard,
        },
        toolbar: ToolbarName.Tools,
        icon: {
            name: 'upload',
            order: 50,
            icon: 'image',
        },
        routeParams: {
            moduleName: '@collboard/internal/add',
            modulePath: null,
        },
    }),
);
