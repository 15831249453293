import { Registration } from 'destroyable';
import React from 'react';
import { TouchFrame } from 'touchcontroller';
import { Transform } from 'xyzt';
import { Separator } from '../30-components/menu/Separator';
import { string_color } from '../40-utils/typeAliases';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { FreehandArt } from '../71-arts/50-FreehandArt/FreehandArt';
import { PointArt } from '../71-arts/50-PointArt/50-PointArt';
internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/freehand-tool',
            deprecatedNames: ['@collboard/freehand-tool', 'FreehandTool'],
            title: { en: 'Drawing', cs: 'Kreslení' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Art'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.33/icons/pen.svg',
            author: Authors.collboard,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            const { attributesSystem } = await systems.request('attributesSystem');
            return {
                //icon: ({ attributesSystem }) => ({
                name: 'freehand' /* For triggering externally */,
                autoSelect: true,
                order: 10,
                icon: 'pen',
                boardCursor: `url("https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.33/cursors/circle-blue.svg") 12.5 12.5, crosshair`,

                /*
                TODO: [🏹][👀] Show the cursor ring on mobile
                TODO: [🏹][👀] Change according to color and weight changes
                TODO: [🏹] Make boardCursor Arrayable (OR some more complex object representing a cursor):
                    > [
                    >     `url("https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.33/cursors/circle-blue.svg")`,
                    >     `url("https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.33/cursors/circle-blue.cur")`,
                    >     'crosshair',
                    > ]
                */

                menu: (
                    <>
                        {attributesSystem.inputRender('weight')}
                        <Separator />
                        {attributesSystem.inputRender('color')}
                    </>
                ),
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, appState, attributesSystem, materialArtVersioningSystem } =
                    await systems.request(
                        'touchController',
                        'collSpace',
                        'appState',
                        'attributesSystem',
                        'materialArtVersioningSystem',
                    );
                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe((touch) => {
                        appState.cancelSelection();

                        const color = attributesSystem.getAttributeValue('color').value as string_color;
                        const weight = attributesSystem.getAttributeValue('weight').value as number;

                        const freehandInProcess = new FreehandArt([], color, weight);

                        const operation = materialArtVersioningSystem.createPrimaryOperation();
                        operation.newArts(freehandInProcess);

                        registerAdditionalSubscription(
                            touch.frames.subscribe({
                                // TODO: There should be some predetermined order which subscriber (freehand,move,...) to call first which second... and it should be determined by module priority NOT installation (subscription) order
                                async next(touchFrame) {
                                    // TODO: [👘] Nicer syntax
                                    const frame = touchFrameToArtFrame(touchFrame);
                                    frame.position = collSpace.pickPoint(frame.position).point;

                                    operation.update(
                                        freehandInProcess.pushFrame(frame) /* <- TODO: Make pushFrame + use ACRY */,
                                    );
                                },
                                complete() {
                                    if (freehandInProcess.frames.length === 1) {
                                        operation.update(
                                            new PointArt(
                                                {
                                                    spotSize: weight,
                                                },
                                                {
                                                    color,
                                                },
                                                Transform.translate(freehandInProcess.frames[0]!.position),
                                            ),
                                        );
                                    } else {
                                        freehandInProcess.smoothing = weight * 0.3;
                                        // <- TODO: Allow to choose via attribute
                                        operation.update(freehandInProcess);
                                    }

                                    operation.persist();
                                },
                            }),
                        );
                    }),
                );
            },
        },
    }),
);

/**
 * @@x
 *
 * [👘]
 * @collboard-modules-sdk
 */
export function touchFrameToArtFrame(frame: TouchFrame): Pick<TouchFrame, 'position' | 'time'> {
    return {
        position: frame.position,
        time: Math.floor(frame.time || 0) /* <- [🧠] */,
    };
}

/**
 * TODO: [🧠] What is the best way to capture the time
 * TODO: [🧠] Better way how to do record current time of the frame
 * TODO: [🧠] Migrations: There should be some way how to free up space after freehand made by broken frame.clone freehand
 * TODO: [👀] Focus cursor from other users in unfinished Freehands
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 * TODO: If not dragging fallback to PointArt
 * TODO: [🈁]
 */
